
/* Home Page */ 
body{
  color: #6F8BA4;
  margin-top:20px;
}
.section {
  padding: 100px 0;
  position: relative;
}
.center {
  margin: auto;
}
.gray-bg {
  background-color: #f5f5f5;
}
img {
  max-width: 100%;
}
img {
  vertical-align: middle;
  border-style: none;
}
/* About Me 
---------------------*/
.about-text h3 {
font-size: 45px;
font-weight: 700;
margin: 0 0 6px;
}
@media (max-width: 767px) {
.about-text h3 {
  font-size: 35px;
}
}
.about-text h6 {
font-weight: 600;
margin-bottom: 15px;
}
@media (max-width: 767px) {
.about-text h6 {
  font-size: 18px;
}
}
.about-text p {
font-size: 18px;
max-width: 450px;
}
.about-text p mark {
font-weight: 600;
color: #20247b;
}

.about-list {
padding-top: 10px;
}
.about-list .media {
padding: 5px 0;
}
.about-list label {
color: #20247b;
font-weight: 600;
width: 88px;
margin: 0;
position: relative;
}
.about-list label:after {
content: "";
position: absolute;
top: 0;
bottom: 0;
right: 11px;
width: 1px;
height: 12px;
background: #20247b;
-moz-transform: rotate(15deg);
-o-transform: rotate(15deg);
-ms-transform: rotate(15deg);
-webkit-transform: rotate(15deg);
transform: rotate(15deg);
margin: auto;
opacity: 0.5;
}
.about-list p {
margin: 0;
font-size: 15px;
}

@media (max-width: 991px) {
.about-avatar {
  margin-top: 30px;
}
}

.about-section .counter {
padding: 22px 20px;
background: #ffffff;
border-radius: 10px;
box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
}
.about-section .counter .count-data {
margin-top: 10px;
margin-bottom: 10px;
}
.about-section .counter .count {
font-weight: 700;
color: #20247b;
margin: 0 0 5px;
}
.about-section .counter p {
font-weight: 600;
margin: 0;
}
mark {
  background-image: linear-gradient(rgba(252, 83, 86, 0.6), rgba(252, 83, 86, 0.6));
  background-size: 100% 3px;
  background-repeat: no-repeat;
  background-position: 0 bottom;
  background-color: transparent;
  padding: 0;
  color: currentColor;
}
.theme-color {
  color: #ff5b5c;
}
.dark-color {
  color: #20247b;
}

/* Portfolio */

.box {
  width: 90px; height: 90px; margin-top: -45px;
}
.card::after {
  display: block;
  position: absolute;
  bottom: -10px;
  left: 20px;
  width: calc(100% - 40px);
  height: 35px;
  background-color: #fff;
  -webkit-box-shadow: 0 19px 28px 5px rgba(64,64,64,0.09);
  box-shadow: 0 19px 28px 5px rgba(64,64,64,0.09);
  content: '';
  z-index: -1;
}
a.card {
  text-decoration: none;
}

.card {
  position: relative;
  border: 0;
  border-radius: 0;
  background-color: #fff;
  -webkit-box-shadow: 0 12px 20px 1px rgba(64,64,64,0.09);
  box-shadow: 0 12px 20px 1px rgba(64,64,64,0.09);
}
.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,0.125);
  border-radius: .25rem;
}

.box-shadow {
  -webkit-box-shadow: 0 12px 20px 1px rgba(64,64,64,0.09) !important;
  box-shadow: 0 12px 20px 1px rgba(64,64,64,0.09) !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}
.mr-auto, .mx-auto {
  margin-right: auto !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.bg-white {
  background-color: #fff !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}
.d-block {
  display: block !important;
}
img, figure {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.card-text {
  padding-top: 12px;
  color: #8c8c8c;
  height: 40px;
}

.text-sm {
  font-size: 12px !important;
}
p, .p {
  margin: 0 0 16px;
}

.card-title {
  margin: 0;
  font-family: "Montserrat",sans-serif;
  font-size: 18px;
  font-weight: 900;
}

.pt-1, .py-1 {
  padding-top: .25rem !important;
  color: #20247b
}



.head-icon{
  margin-top:18px;
  color:#ff5b5c
}

/* Vector Map */

.vector-map{
  width: 600px;
  height: 600px;
}